<template>
  <purchase-step class="is-relative" :step="2">
    <b-loading :active="isProcessing" :is-full-page="false"></b-loading>
    <div class="box has-logo mb-2" style="width: 100%; height: 120px" v-if="isUpgradeEvent">
      <figure class="image is-centered" style="width: 100%; height: 100%">
        <div class="upgrade-banner" style="width: 100%; height: 100%"></div>
      </figure>
    </div>

    <h1 class="title is-3 mb-3">
      <template v-if="isMebleEvent">
        {{ $t("custom.meble_invoice_data") }}
      </template>
      <template v-else-if="isPismEvent || areAllFree">
        {{ $t("ticket.free_ticket_consents") }}
      </template>
      <template v-else>
        {{ $t("payment.payment_method") }}
      </template>
    </h1>

    <template v-if="!requiresPayment && !isPismEvent && !isMebleEvent && !isDtsEvent && !isProExpertEvent && !isXEvent">
      <p class="mb-3 subtitle">{{ $t("payment.ticket_is_free") }}</p>
    </template>

    <template v-else-if="requiresPayment && !isPismEvent && !wantProformaInvoice">
      <div class="form mb-3">
        <div class="field">
          <div class="control">
            <div class="payment-method-list">
              <label class="payment-method" v-if="payuVisible">
                <input type="radio" value="payu" v-model="paymentMethod" />
                <div class="box">
                  <figure class="image is-100x100 is-centered">
                    <img src="@/assets/payment/payu_logo.png" />
                  </figure>
                </div>
              </label>
              <label class="payment-method" v-if="p24Visible">
                <input type="radio" value="p24" v-model="paymentMethod" />
                <div class="box">
                  <figure class="image is-100x100 is-centered">
                    <img src="@/assets/payment/przelewy24_logo.png" />
                  </figure>
                </div>
              </label>
              <label class="payment-method" v-if="stripeVisible">
                <input type="radio" value="stripe" v-model="paymentMethod" />
                <div class="box">
                  <figure class="image is-100x100 is-centered">
                    <img src="@/assets/payment/stripe_logo.png" />
                    {{ $t("payment.pay_with_card") }}
                  </figure>
                </div>
              </label>
              <label class="payment-method" v-if="blueMediaVisible">
                <input type="radio" value="bluemedia" v-model="paymentMethod" />
                <div class="box">
                  <figure class="image is-centered is-100x100">
                    <autopay-logo class="svg-icon"></autopay-logo>
                  </figure>
                </div>
              </label>
            </div>
          </div>
          <template v-if="$v.paymentMethod.$error">
            <p v-if="!$v.paymentMethod.required" class="help is-danger">{{ $t("payment.choose_payment_method") }}</p>
          </template>
        </div>
      </div>

      <div v-if="gateway === PaymentConstants.GATEWAY_STRIPE_ID" class="stripe mb-3">
        <h1 class="title is-3 mb-2">{{ $t("payment.payment_details") }}</h1>
        <stripe-card :stripe-publish-key="stripePublishKey"></stripe-card>
      </div>

      <!--      <div v-if="gateway === PaymentConstants.GATEWAY_BLUE_MEDIA_ID">-->
      <!--        <blue-media-gateways v-model="blueMediaGatewayId"></blue-media-gateways>-->
      <!--      </div>-->

      <!--      <div v-if="blueMediaGatewayId === PaymentConstants.BLUE_MEDIA_GATEWAYS.GOOGLE_PAY">-->
      <!--        <google-pay :gateway-merchant-id="'asd'"></google-pay>-->
      <!--      </div>-->
    </template>

    <div class="form">
      <!-- Marketing agreement -->
      <div class="field" v-if="hasMarketingAgreement">
        <div class="control">
          <label class="checkbox is-size-7 mb-1">
            <checkbox class="checkbox-input" v-model="marketingAgreement"></checkbox>
            <i18n
              @click="marketingAgreement = !marketingAgreement"
              :path="extraDiscountStringPath"
              tag="span"
              class="consents"
              :class="marketingClass"
            >
              <a v-if="isProExpertEvent" target="_blank" @click.stop="0" :href="$t('custom.per_marketing_privacy_url')">
                {{ extraDiscountSubstring }}
              </a>
              <span v-else>
                {{ extraDiscountSubstring }}
              </span>
            </i18n>
          </label>
        </div>
      </div>

      <!-- Discount code checkbox -->
      <div class="field" v-if="requiresPayment || discount">
        <div class="control">
          <label class="checkbox is-size-7 mb-1">
            <checkbox class="checkbox-input" v-model="showDiscountTextBox"></checkbox>
            <span class="consents" @click="showDiscountTextBox = !showDiscountTextBox">
              <template>
                {{ $t("payment.discount_code_checkbox") }}
              </template>
            </span>
          </label>
        </div>
        <div v-if="showDiscountTextBox" class="mt-1">
          <div class="control is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
            <input
              class="input"
              type="text"
              :disabled="!!discount"
              :placeholder="$t('payment.discount_code_placeholder')"
              v-model="discountCode"
            />
            <b-button class="ml-1" type="is-primary" rounded @click="checkCode" v-if="!discount">
              {{ $t("payment.discount_code_apply") }}
            </b-button>
          </div>
          <p v-if="discountError" class="help is-danger">
            <template v-if="discountCodeErrorStatusCode === Constants.PAYMENT_DISCOUNT_CODE_INVALID_ERROR_CODE">
              {{ $t("payment.discount_code_error") }}
            </template>
            <template v-else-if="discountCodeErrorStatusCode === Constants.PAYMENT_DISCOUNT_CODE_USED_ERROR_CODE">
              {{ $t("payment.discount_code_used_error") }}
            </template>
            <template v-else>
              {{ discountError | pluckError }}
            </template>
          </p>
          <p class="help has-text-success" v-if="discount">
            {{ $t("payment.discount_code_success") }}
          </p>
        </div>
      </div>

      <!-- Custom ticket sales terms -->
      <div class="field" v-if="ticketSalesTermsVisible">
        <div class="control">
          <label class="checkbox is-size-7 mb-1">
            <checkbox class="checkbox-input" v-model="$v.accept_custom_ticket_sales_terms.$model"></checkbox>
            <i18n
              :path="ticketSalesTermsText"
              tag="span"
              class="consents"
              :class="ticketSalesTermsClass"
              @click="$v.accept_custom_ticket_sales_terms.$model = !$v.accept_custom_ticket_sales_terms.$model"
            >
              <a target="_blank" @click.stop="0" :href="ticketTermsUrl">
                {{ ticketTermsSubstring }}
              </a>
            </i18n>
          </label>
        </div>
        <template v-if="$v.accept_custom_ticket_sales_terms.$error">
          <p v-if="!$v.accept_custom_ticket_sales_terms.accept" class="help is-danger">{{ $t("auth.consent_required") }}</p>
        </template>
      </div>

      <!-- Gdpr consent -->
      <div class="field" v-if="gdprVisible && !isMebleEvent">
        <div class="control">
          <label class="checkbox is-size-7 mb-1">
            <checkbox class="checkbox-input" v-model="$v.gdprAccepted.$model"></checkbox>
            <i18n
              path="payment.gdpr_consent"
              tag="span"
              class="consents"
              :class="gdprConsentClass"
              @click="$v.gdprAccepted.$model = !$v.gdprAccepted.$model"
            >
              <a target="_blank" @click.stop="0" :href="$t('url.terms')">
                {{ $t("payment.gdpr_terms_of_service_substring") }}
              </a>
              <a target="_blank" @click.stop="0" :href="$t('url.gdpr')">
                {{ $t("payment.gdpr_read_more_substring") }}
              </a>
            </i18n>
          </label>
        </div>
        <template v-if="$v.gdprAccepted.$error">
          <p v-if="!$v.gdprAccepted.accept" class="help is-danger">{{ $t("auth.consent_required") }}</p>
        </template>
      </div>

      <template v-if="!isMebleEvent">
        <!-- Privacy policy and terms of service consent -->
        <div class="field" ref="privacyPolicyField">
          <div class="control">
            <label class="checkbox">
              <checkbox class="checkbox-input" v-model="terms"></checkbox>
              <i18n path="auth.terms_and_conditions" tag="span" class="consents" :class="termsClass" @click="terms = !terms">
                <a
                  target="_blank"
                  @click.stop="0"
                  href="https://drive.google.com/file/d/18jtzHmBF8LmLi4CVJ317EOVJp6NyPK8b/view?usp=sharing"
                  v-if="isUpgradeEvent"
                  >{{ $t("auth.privacy_policy_substring") }}</a
                >
                <a target="_blank" @click.stop="0" :href="$t('url.privacy')" v-else>{{ $t("auth.privacy_policy_substring") }}</a>
                <a target="_blank" @click.stop="0" :href="$t('url.terms')">{{ $t("auth.terms_of_service_substring") }}</a>
              </i18n>
            </label>
            <template v-if="$v.terms.$error">
              <p v-if="!$v.terms.accept" class="help is-danger">{{ $t("auth.consent_required") }}</p>
            </template>
          </div>
        </div>

        <!-- GDPR consent -->
        <div class="field mb-3" ref="gdprField">
          <div class="control">
            <label class="checkbox consents">
              <checkbox class="checkbox-input" v-model="gdpr"></checkbox>
              <i18n :path="gdprStringPath" tag="span" class="consents" :class="gdprClass" @click="gdpr = !gdpr">
                <a target="_blank" @click.stop="0" :href="$t('url.gdpr')">
                  {{ $t("auth.read_more") }}
                </a>
              </i18n>
            </label>
            <template v-if="$v.gdpr.$error">
              <p v-if="!$v.gdpr.accept" class="help is-danger">{{ $t("auth.consent_required") }}</p>
            </template>
          </div>
        </div>
      </template>

      <template v-if="requiresPayment || isMebleEvent">
        <invoice-form ref="form" :value="invoiceForm" @input="setInvoiceForm" @validation="validation"></invoice-form>
      </template>
    </div>

    <template #buttons>
      <button class="button is-light is-rounded" @click="previous">{{ $t("payment.previous") }}</button>
      <button class="button is-primary is-rounded ml-auto" @click="requestPayment">
        <template v-if="isMebleEvent || isProExpertEvent">{{ $t("custom.meble_reserve") }}</template>
        <template v-else-if="requiresPayment">{{ $t("payment.pay") }}</template>
        <template v-else>{{ $t("payment.confirm") }}</template>
      </button>
    </template>

    <template #errors>
      <template v-if="error">
        <p class="help is-danger">
          <template v-if="initErrorStatusCode === Constants.PAYMENT_INIT_TICKET_SOLD_OUT_ERROR_CODE">
            {{ $t("payment.init_ticket_sold_out_error") }}
          </template>
          <template v-else-if="initErrorStatusCode === Constants.PAYMENT_INIT_TICKET_NOT_FOUND_ERROR_CODE">
            {{ $t("payment.init_ticket_not_found_error") }}
          </template>
          <template v-else-if="initErrorStatusCode === Constants.PAYMENT_INIT_CODE_USED_ERROR_CODE">
            {{ $t("payment.init_discount_code_used_error") }}
          </template>
          <template v-else-if="initErrorStatusCode === Constants.PAYMENT_INIT_CODE_NOT_FOUND_ERROR_CODE">
            {{ $t("payment.init_discount_code_not_found_error") }}
          </template>
          <template v-else>
            {{ error | pluckError }}
          </template>
        </p>
      </template>
    </template>
  </purchase-step>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

import InvoiceForm from "@/web/components/forms/InvoiceForm";
import PurchaseStep from "./PurchaseStep";
import AutopayLogo from "@/assets/payment/autopay_logo.svg";
import StripeCard from "../../../shared/components/payment/StripeCard";
import Constants from "@/web/constants";
import PaymentConstants from "@/shared/constants/payment-constants";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import Checkbox from "@/shared/components/form/Checkbox";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
// import BlueMediaGateways from "@/shared/components/payment/BlueMediaGateways";
// import GooglePay from "@/shared/components/payment/GooglePay";

export default {
  name: "PurchasePaymentStep",
  mixins: [LpConfigMixin],
  components: {
    // GooglePay,
    // BlueMediaGateways,
    InvoiceForm,
    PurchaseStep,
    StripeCard,
    Checkbox,
    AutopayLogo,
  },

  data() {
    return {
      isProcessing: false,
      error: null,
      paymentMethod: null,
      accept_custom_ticket_sales_terms: false,
      showDiscountTextBox: false,
      marketingAgreement: false,
      discountCode: "",
      discountError: null,
      //Guest tickets
      gdprAccepted: false,
      //Unauthorized user
      terms: false,
      gdpr: false,
      //BlueMedia
      blueMediaGatewayId: null,
    };
  },

  beforeDestroy() {
    this.setDiscount(null);
    this.setMarketingAgreement(false);
  },

  validations() {
    const validations = {
      // accept_ma_tickets: { required: value => !!value },
      accept_custom_ticket_sales_terms: this.ticketSalesTermsVisible ? { required: value => !!value } : {},
    };

    if (!this.isMebleEvent) {
      validations.gdprAccepted = this.gdprVisible ? { required: value => !!value } : {};
      validations.gdpr = { required: value => !!value };
      validations.terms = { required: value => !!value };
    }

    if (this.isProExpertEvent) {
      validations.marketingAgreement = { required: value => !!value };
    }

    if (this.requiresPayment) {
      validations.paymentMethod = { required };
    }
    return validations;
  },

  methods: {
    ...mapActions(["buyTicket"]),
    ...mapMutations("payment", [
      "setInvoiceForm",
      "setConsentForm",
      "setGateway",
      "setDiscount",
      "setMarketingAgreement",
      "setTransactionStatus",
    ]),
    ...mapActions("payment", ["checkDiscountCode"]),

    validation(invalid) {
      this.isFormInvalid = invalid;
    },

    wantInvoiceCheckboxValidSelected() {
      return this.invoiceForm.want_invoice || this.invoiceForm.want_personal_invoice;
    },

    async requestPayment() {
      if (this.requiresPayment && !this.wantInvoiceCheckboxValidSelected()) {
        this.$refs.form?.touch();
        return;
      }
      if (
        this.isFormInvalid &&
        this.invoiceForm.want_invoice &&
        (this.gateway !== PaymentConstants.GATEWAY_FREE_TICKET_ID || this.isMebleEvent)
      ) {
        this.$v.$touch();
        if (this.$refs.form) {
          this.$refs.form.touch();
        }
        return;
      } else if (this.$v.$invalid) {
        if (this.$v.paymentMethod.value === null && this.wantProformaInvoice) {
        }
        this.$refs.form?.touch();
        this.$v.$touch();
        return;
      }

      this.isProcessing = true;
      this.error = null;

      // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.PURCHASE_EVENT_TICKET_PAYMENT, {
      //   [Constants.ANALYTICS_PARAMETERS.PAYMENT_TYPE]: this.gateway,
      // });

      try {
        let gdprConsent = this.gdprAccepted;
        await this.buyTicket({ gdprConsent: gdprConsent });
        this.afterPayment();
      } catch (err) {
        this.isProcessing = false;
        if (err.response.data && Array.isArray(err.response.data)) {
          this.$emit("email-errors", err.response.data);
        } else {
          this.error = err;
        }
      } finally {
        this.isProcessing = false;
      }
    },

    afterPayment() {
      // Redirect to ticket buy confirmation
      if (["stripe", "free"].includes(this.gateway)) {
        this.setTransactionStatus(PaymentConstants.TRANSACTION_STATUSES.COMPLETED);
        this.$emit("show-confirmation");
      }
    },

    async checkCode() {
      if (this.discount) {
        this.setDiscount(null);
        this.discountCode = "";
      } else {
        try {
          await this.checkDiscountCode(this.discountCode.toLowerCase());
          this.discountError = null;
        } catch (err) {
          this.discountError = err;
        }
      }
    },

    previous() {
      this.$emit("previous");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },

  computed: {
    ...mapState("payment", ["invoiceForm", "gateway", "discount"]),
    ...mapGetters(["lpSettings"]),
    ...mapState(["event"]),
    ...mapGetters("payment", ["availableGateways", "finalPrice", "gdprConsentRequired", "stripePublishKey"]),
    ...mapGetters("eventTickets", ["areAllFree"]),

    isPismEvent() {
      return this.event.id === 1312;
    },

    isDtsEvent() {
      return this.event.id === 1411;
    },

    isMebleEvent() {
      return this.event.id === 1343 || this.event.id === 245;
    },

    isProExpertEvent() {
      return this.event.id === 1419;
    },

    isXEvent() {
      return this.event.id === 1444;
    },

    isUpgradeEvent() {
      return this.event.id === 1355;
    },

    wantProformaInvoice() {
      return this.invoiceForm?.want_proforma_invoice;
    },

    Constants: () => Constants,
    PaymentConstants: () => PaymentConstants,

    paymentConfig() {
      return this.lpSettings && this.getFieldValueOrDefault(this.lpSettings, LpConfigConstants.ROOT_FIELDS.PAYMENT, {});
    },

    requiresPayment() {
      return this.finalPrice;
    },

    hasMarketingAgreement() {
      return this.event.marketing_enabled;
    },

    marketingDiscount() {
      return this.event.marketing_discount_percentage * 0.01 * -1;
    },

    hasExtraDiscountForMarketingAgreement() {
      return !!this.marketingDiscount;
    },

    extraDiscountSubstring() {
      if (this.hasExtraDiscountForMarketingAgreement) {
        if (this.event.id === 1251) {
          return this.marketingDiscount * 100 + "%";
        } else if (this.isProExpertEvent) {
          return this.$t("custom.ped_marketing_substring");
        } else {
          return this.$t("payment.marketing_agreement_discount_substring", [this.marketingDiscount * 100]);
        }
      }
      return "";
    },

    extraDiscountStringPath() {
      if (this.event.id === 1251) {
        return "custom.smakki_extra_discount_text";
      } else if (this.isUpgradeEvent) {
        return "custom.upgrade23_marketing";
      } else if (this.isProExpertEvent) {
        return "custom.ped_marketing_agreement";
      } else {
        return "payment.marketing_agreement_checkbox";
      }
    },

    ticketSalesTermsVisible() {
      return (
        this.event.terms_of_sale_url ||
        (this.paymentConfig && this.getFieldValueOrDefault(this.paymentConfig, LpConfigConstants.PAYMENT_FIELDS.CHECKBOX_TEXT_KEY, false))
      );
    },

    ticketSalesTermsText() {
      if (this.event.terms_of_sale_url) {
        return "payment.accept_ticket_terms";
      }
      return (
        this.paymentConfig &&
        this.getCustomTranslationKey(this.paymentConfig, LpConfigConstants.PAYMENT_FIELDS.CHECKBOX_TEXT_KEY, "custom", null)
      );
    },

    ticketTermsUrl() {
      return (
        this.event.terms_of_sale_url ||
        (this.paymentConfig && this.getCustomTranslation(this.paymentConfig, LpConfigConstants.PAYMENT_FIELDS.TICKET_TERMS_URL, null))
      );
    },

    ticketTermsSubstring() {
      if (this.event.terms_of_sale_url) {
        return this.$t("payment.accept_ticket_terms_substring");
      }
      return (
        this.paymentConfig && this.getCustomTranslation(this.paymentConfig, LpConfigConstants.PAYMENT_FIELDS.CHECKBOX_SUBSTRING_KEY, null)
      );
    },

    ticketSalesTermsClass() {
      if (this.ticketSalesTermsVisible && this.$v.$dirty && !this.$v.accept_custom_ticket_sales_terms.required) {
        return "has-text-danger";
      } else {
        return "";
      }
    },

    gdprStringPath() {
      if (this.event.id === 1251) {
        return "custom.smakki_gdpr_text";
      } else if (this.isUpgradeEvent) {
        return "custom.upgrade23_terms";
      } else {
        return "auth.gdpr_terms";
      }
    },

    gdprClass() {
      if (this.$v.$dirty && !this.$v.gdpr.required) {
        return "has-text-danger";
      } else {
        return "";
      }
    },

    termsClass() {
      if (this.$v.$dirty && !this.$v.terms.required) {
        return "has-text-danger";
      } else {
        return "";
      }
    },

    marketingClass() {
      if (!!this.$v.marketingAgreement && this.$v.$dirty && !this.$v.marketingAgreement.required && this.isProExpertEvent) {
        return "has-text-danger";
      } else {
        return "";
      }
    },

    gdprVisible() {
      return this.gdprConsentRequired;
    },

    gdprConsentClass() {
      if (this.gdprVisible && this.$v.$dirty && !this.$v.gdprAccepted.required) {
        return "has-text-danger";
      } else {
        return "";
      }
    },

    initErrorStatusCode() {
      return (
        this.error &&
        this.error.response &&
        this.error.response.data &&
        this.error.response.data.errors &&
        this.error.response.data.errors.length &&
        this.error.response.data.errors[0].code
      );
    },

    discountCodeErrorStatusCode() {
      return (
        this.discountError &&
        this.discountError.response &&
        this.discountError.response.data &&
        this.discountError.response.data.errors &&
        this.discountError.response.data.errors.length &&
        this.discountError.response.data.errors[0].code
      );
    },

    payuVisible() {
      return this.availableGateways.includes(PaymentConstants.GATEWAY_PAYU_ID);
    },

    p24Visible() {
      return this.availableGateways.includes(PaymentConstants.GATEWAY_P24_ID);
    },

    stripeVisible() {
      return this.availableGateways.includes(PaymentConstants.GATEWAY_STRIPE_ID);
    },

    proformaVisible() {
      return this.availableGateways.includes(PaymentConstants.GATEWAY_PROFORMA_ID);
    },

    blueMediaVisible() {
      return this.availableGateways.includes(PaymentConstants.GATEWAY_BLUE_MEDIA_ID);
    },
  },

  watch: {
    paymentMethod: {
      immediate: true,
      handler: function (gateway) {
        this.setGateway(gateway);
      },
    },

    wantProformaInvoice: {
      immediate: true,
      handler: function (state) {
        if (state) {
          this.paymentMethod = PaymentConstants.GATEWAY_PROFORMA_ID;
        } else {
          if (this.gateway && this.gateway !== PaymentConstants.GATEWAY_PROFORMA_ID) {
            return;
          } else {
            this.paymentMethod = null;
          }
        }
      },
    },

    // availableGateways: {
    //   immediate: true,
    //   handler: function (newValue) {
    //     if (newValue && newValue.length === 1) {
    //       this.paymentMethod = newValue[0];
    //     }
    //   },
    // },

    requiresPayment: {
      immediate: true,
      handler: function (value) {
        if (value && this.availableGateways.length === 1) {
          this.paymentMethod = this.availableGateways[0];
        } else if (!value) {
          this.paymentMethod = PaymentConstants.GATEWAY_FREE_TICKET_ID;
        } else {
          this.paymentMethod = null;
        }
      },
    },

    consentFormData(value) {
      this.setConsentForm(value);
    },

    showDiscountTextBox(value) {
      if (!value) {
        this.discountCode = "";
        this.setDiscount(null);
      }
    },

    discount(value) {
      if (value) {
        this.discountCode = value.discount_code.toUpperCase();
        this.showDiscountTextBox = true;
      }
    },

    marketingAgreement: {
      immediate: true,
      handler: function (newValue) {
        this.setMarketingAgreement(newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.consents {
  font-size: 15px;
  white-space: pre-line;
}

.upgrade-banner {
  background-image: url(~@/assets/payment/payments_banner.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
